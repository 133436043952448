import React, { useEffect, useState } from 'react'
import { DepositManagers } from './DepositManager.styles';
import { useForm } from "react-hook-form";
import { getListDeposit, historyNap, historyNapUpdate } from '../../../services/userManServices'
import Notiflix from 'notiflix';
import loadImg from "../../../assets/images/loadImg.png";
import axiosClient, {API_ENDPOINT} from "../../../services/axiosClient";
import { getToken } from "../../../utils/localStorage";

function DepositManager() {
  const [bank, setBank] = useState('');
  const [account, setAccount] = useState('');
  const [branch, setBranch] = useState('');
  const [name, setName] = useState('');
  const [search, setSearch] = useState('');
  const [listPosit, setListPosit] = useState([]);
  const [avt, setAvt] = useState(null); // Biến để lưu trữ file ảnh

  const { register, handleSubmit, formState: { errors } } = useForm();
  var  imageLink;

  // Lấy thông tin ban đầu
  useEffect(() => {
    historyNap()
        .then(res => {
          setBank(res.data.bankName);
          setAccount(res.data.bankNumber);
          setBranch(res.data.bankBranch);
          setName(res.data.accountName);

         if (getToken() && res.data.cardImage) {
             // link  = loca/api/user/image/g.jp&token=adsfkjahskjdf
          imageLink = API_ENDPOINT + 'user/image/' + res.data.cardImage + '&{token}?token=' + `${getToken()}`;

         }
         setAvt(imageLink)
          console.log(imageLink)
        })
        .catch(err => {
          console.log(err);
        })

    getListDeposit(search)
        .then(res => {
          setListPosit(res.data);
        })
  }, [search]);

  // Hàm cập nhật thông tin
  const handleUpdate = () => {
    const formData = new FormData();
    formData.append("bankName", bank);
    formData.append("bankNumber", account);
    formData.append("bankBranch", branch);
    formData.append("accountName", name);

    if (avt) {
      formData.append("cardImage", avt); // Thêm ảnh vào FormData
    }

    historyNapUpdate(formData)
        .then(res => {
          Notiflix.Notify.success('Cập nhật thành công');
        })
        .catch(err => {
          console.log(err);
          if (err.response.data.code === 'BAD_REQUEST') {
            Notiflix.Notify.failure('Vui lòng nhập đúng định dạng số tài khoản');
          }
        })
  };

  return (
      <DepositManagers>
        <DepositManagers.Header>
          <span>Quản lý nạp</span>
        </DepositManagers.Header>
        <DepositManagers.Body>
          <DepositManagers.Left onSubmit={handleSubmit(handleUpdate)}>
            <div className='bg-[#B9D2FF] py-[25px] pl-[40px]'>
              <span className='text-[#000650] font-medium'>Cấu hình thông tin ngân hàng</span>
            </div>
            <div className='p-[40px] flex flex-col gap-[20px]'>
              <div className='flex flex-col gap-[10px]'>
                <span className='text-[#000650] font-medium'>Ngân hàng</span>
                <input
                    className='outline-none py-[10px] rounded-md px-[15px]'
                    onChange={e => setBank(e.target.value)}
                    value={bank}
                />
                {errors?.bank?.type === "maxLength" && (<p>Tên phải trên 3 ký tự</p>)}
                {errors?.bank?.type === "required" && (<p>Vui lòng không bỏ trống</p>)}
              </div>
              <div className='flex flex-col gap-[10px]'>
                <span className='text-[#000650] font-medium'>Số tài khoản</span>
                <input
                    className='outline-none py-[10px] rounded-md px-[15px]'
                    onChange={e => setAccount(e.target.value)}
                    value={account}
                />
                {errors?.account?.type === "pattern" && (<p>nhập sai định dạng</p>)}
                {errors?.account?.type === "required" && (<p>Vui lòng không bỏ trống</p>)}
              </div>
              <div className='flex flex-col gap-[10px]'>
                <span className='text-[#000650] font-medium'>Chi nhánh</span>
                <input
                    className='outline-none py-[10px] rounded-md px-[15px]'
                    onChange={e => setBranch(e.target.value)}
                    value={branch}
                />
                {errors?.branch?.type === "required" && (<p>Vui lòng không bỏ trống</p>)}
              </div>
              <div className='flex flex-col gap-[10px]'>
                <span className='text-[#000650] font-medium'>Tên chủ tài khoản</span>
                <input
                    className='outline-none py-[10px] rounded-md px-[15px]'
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
                {errors?.name?.type === "required" && (<p>Vui lòng không bỏ trống</p>)}
              </div>
              <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnAvt').click()}>
                <label className="block text-center text-sm font-medium leading-6 text-gray-900">QR Bank</label>
                <div className="border w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 bg-white border-gray-300 rounded-md">
                  <img className={avt ? 'h-full' : 'w-[30px]'} src={avt ? avt : loadImg} alt="avatar"/>
                </div>
                {errors?.avt?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng tải ảnh lên </p>}
              </div>

              {/* input img hidden */}
              <div hidden>
                <input
                    id="btnAvt"
                    type="file"
                    {...register("avt", { required: true })}
                    onChange={(event) => {
                      setAvt(event.target.files[0]);
                        console.log('check hình: ',event.target.files[0])
                    }}
                />
              </div>

              <button className='text-[#fff] rounded-lg bg-[#24CF41] py-[15px]'>Cập nhật</button>
            </div>
          </DepositManagers.Left>
        </DepositManagers.Body>
      </DepositManagers>
  )
}

export default DepositManager;
