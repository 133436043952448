import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdmin, login } from '../../services/authServices';
import { setToken } from '../../utils/localStorage';
import Notiflix from 'notiflix';

export const fetchLogin = createAsyncThunk(
    'fetchLoginStatus',
    async (data) => {
        try {
            const resLogin = await login(data)
            await setToken(resLogin.data.accessToken);
            const user = await getAdmin();
            Notiflix.Loading.remove();
            Notiflix.Notify.success('Đăng nhập thành công')
            return user.data;
        } catch (error) {
            Notiflix.Notify.failure('Tài khoản không tồn tại hoặc bị khóa')
            Notiflix.Loading.remove();
        }
    }
)

export const getUserInfor = createAsyncThunk(
    'getUserInforStatus',
    async () => {
        const user = await getAdmin();
        return user.data;
    }
)