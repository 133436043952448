import axios from "axios";
import { stringify, parse } from "qs";
import { getToken } from "../utils/localStorage";
// export const API_ENDPOINT = "http://bestbid.pro/api/"; // API ví dụ
// export const API_ENDPOINT = "https://backend.bestbid.pro/api/"; // API ví dụ
export const API_ENDPOINT = process.env.REACT_APP_APIURL; // API ví dụ

const AxiosClient = axios.create({

    baseURL: API_ENDPOINT,
    responseType: "json",
    timeout: 50000,
    paramsSerializer: {
        encode: parse,
        serialize: stringify,
    },
});

AxiosClient.interceptors.request.use(
    async (config) => {
        const newConfig = config;
        if (getToken()) {
            newConfig.headers.Authorization = `Bearer ${getToken()}`;
        }
        return newConfig;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default AxiosClient;