import React, { useState } from 'react'
// import { SettingW } from './setting.styles';
// import dele from '../../../assets/images/delete.png'
// import checkTrue from '../../../assets/images/checkTrue.png'
// import checkFalse from '../../../assets/images/checkFalse.png'
// import Modal from 'react-modal';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { getTradingConfig, updateTradingConfig } from '../../../services/configBySell';
import Notiflix from 'notiflix';
import { createTimeConfig, deleteTimeConfig, getTimeConfig } from '../../../services/configTime';
import { format } from 'date-fns'
// import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import Header from '../../../components/header/Header';
import { Badge, Button, Card, Label, Table, TextInput } from 'flowbite-react';
import Datepicker from "tailwind-datepicker-react"
import { IoArrowBackSharp, IoArrowForwardSharp, IoRefreshSharp } from 'react-icons/io5';
import TimeField from 'react-simple-timefield';
import { FaRegTrashAlt } from 'react-icons/fa';
// import moment from 'moment';

const optionsDate = {
  title: "Ngày Giao Dịch",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  maxDate: new Date("2030-01-01"),
  minDate: new Date("2000-01-01"),
  theme: {
    background: "bg-white dark:bg-gray-800",
    todayBtn: "",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "",
    input: "",
    inputIcon: "",
    selected: "",
  },
  icons: {
    // () => ReactElement | JSX.Element
    prev: () => <span><IoArrowBackSharp /></span>,
    next: () => <span><IoArrowForwardSharp /></span>,
  },
  datepickerClassNames: "top-12",
  defaultDate: new Date(),
  language: "vn",
}

function Setting() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(format(new Date(), 'yyyy-MM-dd'));
  // const [time, setTime] = useState('');
  const [check, setCheck] = useState("BUY");
  const [configBuy, setConfigBuy] = useState('');
  const [configSell, setConfigSell] = useState('');
  const [listTime, setListTime] = useState([]);
  const [onTime, setOnTime] = useState(new Date().setMinutes(new Date().getMinutes() + 1));
  const [onTime2, setOnTime2] = useState(new Date().setMinutes(new Date().getMinutes() + 5));
  const [startTime, setStartTime] = useState(format(new Date().setMinutes(new Date().getMinutes() + 1), 'HH:mm'));
  const [show, setShow] = React.useState(false)
  const [endTime, setEndTime] = useState(format(new Date().setMinutes(new Date().getMinutes() + 5), 'HH:mm'));
  const handleChange = (selectedDate) => {
    setValue(format(selectedDate, 'yyyy-MM-dd'));
  }
  const handleClose = (state) => {
    setShow(state)
  }
  // config modal
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.4)'
    }
  }
  // get info trading config
  useEffect(() => {
    getTradingConfig()
      .then(res => {
        setConfigBuy(res.data.winRate);
        setConfigSell(res.data.lossRate);
      })
      .catch(err => {
        console.log(err);
      })
  }, [])
  // get time config
  useEffect(() => {
    getTimeConfig()
      .then(res => {
        setListTime(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [])

  function openModal() {
    setStartTime(dayjs(new Date()))
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  // use form
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm();
  const onSubmit = () => {
    if (!value) {
      Notiflix.Notify.failure('không bỏ trống ngày')
    } else if (!startTime) {
      Notiflix.Notify.failure('không bỏ trống thời gian bắt đầu')
    } else if (!endTime) {
      Notiflix.Notify.failure('không bỏ trống thời gian kết thúc')
    } else {
      console.log(check);
      createTimeConfig({
        "startDate": value,
        "startTime": `${startTime}:00`,
        "endTime": `${endTime}:59`,
        "status": check === "BUY" ? "BUY" : "SELL"
      })
        .then(res => {
          Notiflix.Notify.success('Thêm mới thành công')
          getTimeConfig()
            .then(res => {
              setListTime(res.data);
            })
            .catch(err => {
              console.log(err);
            })
          closeModal()
        })
        .catch(err => {
          if (err.response.data.code == "TIME_CONFIG_EXIST") {
            Notiflix.Notify.failure('Thời gian đã được tạo')
          } else {
            switch (err.response.data.code) {
              case 'TIME_CONFIG_EXIST':
                Notiflix.Notify.failure('Thời gian tồn tại')
                break;
              case 'TIME_INVALID':
                Notiflix.Notify.failure('Thời gian không hợp lệ')
                break;
              default:
                Notiflix.Notify.failure('cấu hình thời gian thất bại')
                break;
            }
          }
        })
    }
  }
  const percents = (data => {
    updateTradingConfig({
      winRate: configBuy,
      lossRate: configSell
    })
      .then(res => {
        Notiflix.Notify.success('cập nhật thành công')
      })
      .catch(er => {
        Notiflix.Notify.failure('vui lòng không bỏ trống')
      })
  })
  // delete time config
  const handleDele = (id) => {
    deleteTimeConfig(id)
      .then(res => {
        Notiflix.Notify.success('Xoá thành công')
        getTimeConfig()
          .then(res => {
            setListTime(res.data);
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(err => {
        Notiflix.Notify.failure('Xoá không thành công')
      })
  }

  const handleStartTime = (e) => {
    var date = new Date(onTime).setMinutes(new Date(onTime).getMinutes() + e)
    setOnTime(new Date(date))
    setStartTime(format(new Date(date), 'HH:mm'));
  }

  const handleEndTime = (e) => {
    var date = new Date(onTime2).setMinutes(new Date(onTime2).getMinutes() + e)
    setOnTime2(new Date(date))
    setEndTime(format(new Date(date), 'HH:mm'));
  }

  const onValueChange = (e) => {
    setCheck(e.target.value)
  }

  return (
    <div>
      <Header />
      <div className='px-2 grid grid-cols-2 gap-5'>
        <Card className='col-span-1'>
          <h3 className='font-bold'>CẤU HÌNH % THẮNG THUA</h3>
          <form className="flex max-w flex-col gap-4">
            <div className="w-full flex justify-between">
              <TextInput
                addon="Thắng"
                id="Thắng"
                placeholder="%"
                onChange={e => setConfigBuy(e.target.value)}
                value={configBuy}
                required
              />
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(5)}>5%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(10)} >10%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(15)} >15%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(20)} >20%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(25)} >25%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(30)} >30%</Button>
              <Button gradientDuoTone="cyanToBlue" outline pill onClick={() => setConfigBuy(35)} >35%</Button>
            </div>
            <div className="w-full flex justify-between">
              <TextInput
                addon="Thua "
                id="Thua"
                placeholder="%"
                onChange={e => setConfigSell(e.target.value)}
                value={configSell}
                required
              />
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(30)} >30%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(35)} >35%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(40)} >40%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(45)} >45%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(50)} >50%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(55)} >55%</Button>
              <Button gradientDuoTone="pinkToOrange" outline pill onClick={() => setConfigSell(60)} >60%</Button>
            </div>
            <Button onClick={percents} pill>CẬP NHẬT HỆ THỐNG</Button>
          </form>
        </Card>
        <Card className='col-span-1'>
          <h3 className='font-bold'>CẤU HÌNH THỜI GIAN THẮNG THUA</h3>
          <div className="block">
            <Label
              htmlFor="date"
              value="Ngày"
            />
            <TextInput value={format(new Date(), 'yyyy-MM-dd')} type="text" id="small" readOnly />
            <fieldset className='flex mt-5'>
              <Label
                htmlFor="date"
                value="Lệnh: "
                className='mr-5'
              />
              <div className="flex items-center mr-5">
                <input id="country-option-1" type="radio" checked={check === "BUY"} onChange={(e) => onValueChange(e)} name="position" value="BUY" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="country-option-1" className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Mua
                </label>
              </div>
              <div className="flex items-center">
                <input id="country-option-2" checked={check === "SELL"} onChange={(e) => onValueChange(e)} type="radio" name="position" value="SELL" className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor="country-option-2" className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Bán
                </label>
              </div>
            </fieldset>
          </div>
          <div className="block">
            <Label
              htmlFor="date"
              value="Thời Gian Bắt Đầu"
            />
            <div className='flex justify-between'>
              <TimeField value={startTime} input=<TextInput type="text" id="small" /> onChange={() => console.log()} />
              <Button color="white" size="xs" onClick={() => window.location.reload(false)}><IoRefreshSharp className='mr-2' /> Tải lại</Button>
              <div className='flex justify-between'>
                <Button pill size="xs" onClick={() => handleStartTime(1)}>+1 Phút</Button>
                <Button pill size="xs" onClick={() => handleStartTime(2)}>+2 Phút</Button>
                <Button pill size="xs" onClick={() => handleStartTime(3)}>+3 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleStartTime(-1)}>-1 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleStartTime(-2)}>-2 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleStartTime(-3)}>-3 Phút</Button>
              </div>
            </div>
          </div>
          <div className="block">
            <Label
              htmlFor="date"
              value="Thời Gian Kết Thúc"
            />
            <div className='flex justify-between'>
              <TimeField value={endTime} input=<TextInput type="text" id="small" /> onChange={() => console.log()} />
              <div className='flex justify-between'>
                <Button pill size="xs" onClick={() => handleEndTime(1)}>+1 Phút</Button>
                <Button pill size="xs" onClick={() => handleEndTime(2)}>+2 Phút</Button>
                <Button pill size="xs" onClick={() => handleEndTime(3)}>+3 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleEndTime(-1)}>-1 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleEndTime(-2)}>-2 Phút</Button>
                <Button pill size="xs" color="failure" onClick={() => handleEndTime(-3)}>-3 Phút</Button>
              </div>
            </div>
          </div>
          <Button pill gradientMonochrome="cyan" onClick={onSubmit}>CẬP NHẬT CẤU HÌNH</Button>
        </Card>
      </div>
      <Card className='col-span-2 customCard2'>
        <div className='grid grid-cols-4 gap-2'>
          <h5 className="col-span-3 mb-2 text-2xl font-bold text-gray-900 dark:text-white">
            LỊCH SỬ CẤU HÌNH
          </h5>
          <div className='flex col-span-1'>
            {/* Datepicker (nếu có) */}
          </div>
        </div>

        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>
              STT
            </Table.HeadCell>
            <Table.HeadCell>
              Ngày
            </Table.HeadCell>
            <Table.HeadCell>
              Thời gian
            </Table.HeadCell>
            <Table.HeadCell>
              Vị trí lệnh
            </Table.HeadCell>
            <Table.HeadCell>
              Tuỳ Chỉnh
            </Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y">
            {
              // Sắp xếp listTime theo ngày và thời gian giảm dần (mới nhất trước)
              listTime
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate) || new Date(`1970/01/01 ${b.startTime}`) - new Date(`1970/01/01 ${a.startTime}`))
                .map((item, index) => (
                  <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {index + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {format(new Date(item.startDate), 'dd/MM/yyyy')}
                    </Table.Cell>
                    <Table.Cell>
                      {item.startTime} - {item.endTime}
                    </Table.Cell>
                    <Table.Cell>
                      {item.status === "BUY" ? <Badge color="success">Mua</Badge> : <Badge color="failure">Bán</Badge>}
                    </Table.Cell>
                    <Table.Cell>
                      <Button onClick={() => handleDele(item.id)} pill size="xs"><FaRegTrashAlt /></Button>
                    </Table.Cell>
                  </Table.Row>
                ))
            }
          </Table.Body>
        </Table>
      </Card>

    </div>
    // <SettingW>

    //   <Modal
    //     isOpen={modalIsOpen}
    //     onRequestClose={closeModal}
    //     style={customStyles}
    //     ariaHideApp={false}
    //   >
    //     <div className='flex flex-col gap-[15px]'>
    //       <span className='w-full text-center font-medium text-[18px]'>Cấu hình thời gian thắng thua</span>
    //       <div className='flex flex-col gap-[10px]'>

    //         <div className='flex flex-col gap-[5px] w-fit'>
    //           <span className='text-[#000650] font-medium text-[17px]'>Chọn ngày</span>
    //           <LocalizationProvider dateAdapter={AdapterDayjs}>
    //             <DatePicker
    //               label="Hôm nay"
    //               value={value}
    //               onChange={(newValue) => {
    //                 setValue(`${newValue?.$y ? newValue.$y : ''}-${newValue?.$M + 1}-${newValue?.$D ? newValue.$D : ''}`);
    //               }}
    //               renderInput={(params) => <TextField {...params} />}

    //             />
    //           </LocalizationProvider>
    //         </div>
    //         {/* time picker */}
    //         <div className='flex flex-col gap-[5px] w-fit'>
    //           <span className='text-[#000650] font-medium text-[17px]'>Thời gian bắt đầu</span>
    //           <LocalizationProvider dateAdapter={AdapterDayjs}>
    //             <Stack spacing={3}>
    //               <TimePicker
    //                 renderInput={(params) => <TextField {...params} />}
    //                 value={startTime}
    //                 label="min/max time"
    //                 onChange={(newValue) => {
    //                   setStartTime(newValue);
    //                 }}
    //                 ampm={false}
    //                 // clearable
    //               />
    //             </Stack>
    //           </LocalizationProvider>
    //         </div>
    //         <div className='flex flex-col gap-[5px] w-fit'>
    //           <span className='text-[#000650] font-medium text-[17px]'>Thời gian kết thúc</span>
    //           <LocalizationProvider dateAdapter={AdapterDayjs}>
    //             <Stack spacing={3}>
    //               <TimePicker
    //                 renderInput={(params) => <TextField {...params} />}
    //                 value={endTime}
    //                 label="min/max time"
    //                 onChange={(newValue) => {
    //                   setEndTime(newValue);
    //                 }}
    //                 ampm={false}
    //                 // clearable
    //               />
    //             </Stack>
    //           </LocalizationProvider>
    //         </div>

    //         <div className='flex flex-col gap-[5px] w-fit'>
    //           <span className='text-[#000650] font-medium text-[17px]'>Trạng thái </span>
    //           <div className='flex gap-[20px] items-center'>
    //             <div
    //               className='flex gap-[5px] items-center cursor-pointer'
    //               onClick={() => setCheck(true)}
    //             >
    //               <img src={check ? checkTrue : checkFalse} className="w-[23px]" />
    //               <span>Buy</span>
    //             </div>
    //             <div
    //               className='flex gap-[5px] items-center cursor-pointer'
    //               onClick={() => setCheck(false)}
    //             >
    //               <img src={check ? checkFalse : checkTrue} className="w-[23px]" />
    //               <span>Sell</span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='w-full flex justify-center gap-[15px]'>
    //         <button
    //           onClick={closeModal}
    //           className='font-bold text-[#fff] text-center w-full py-[12px] rounded bg-[#A1A1A1] cursor-pointer'
    //         >Hủy</button>
    //         <button
    //           onClick={onSubmit}
    //           className='font-bold text-[#fff] text-center w-full py-[12px] rounded bg-[#0082D9] cursor-pointer'
    //         >Xác nhận</button>
    //       </div>
    //     </div>
    //   </Modal>

    //   <SettingW.Header>
    //     <span>Cài đặt hệ thống</span>
    //   </SettingW.Header>
    //   <SettingW.Body>
    //     <SettingW.Left onSubmit={handleSubmit(percents)}>
    //       <div className='bg-[#B9D2FF] py-[25px] pl-[20px]'>
    //         <span className='text-[#000650] font-medium'>Cấu hình % tiền thắng thua</span>
    //       </div>
    //       <div className='px-[20px] py-[20px] flex flex-col gap-[20px]'>
    //         <div className='flex flex-col gap-[10px]'>
    //           <span className='text-[#000650] font-medium'>Thắng</span>
    //           <input
    //             {...register("winRate", {
    //               required: false,
    //               pattern: /^\d+$/
    //             })}
    //             className='outline-none py-[10px] rounded-md px-[15px]'
    //             onChange={e => setConfigBuy(e.target.value)}
    //             value={configBuy}
    //             placeholder='%'
    //           />
    //         </div>
    //         {errors?.isBuy?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng không bỏ trống</p>}
    //         {errors?.isBuy?.type === "pattern" && <p className="text-[#FF0000] text-[14px]">*Nhập sai định dạng số</p>}
    //         <div className='flex flex-col gap-[10px]'>
    //           <span className='text-[#000650] font-medium'>Thua</span>
    //           <input
    //             {...register("lossRate", {
    //               required: false,
    //               pattern: /^\d+$/
    //             })}
    //             className='outline-none py-[10px] rounded-md px-[15px]'
    //             placeholder='%'
    //             onChange={e => setConfigSell(e.target.value)}
    //             value={configSell}
    //           />
    //         </div>
    //         {errors?.isSell?.type === "required" && <p className="text-[#FF0000] text-[14px]">*Vui lòng không bỏ trống</p>}
    //         {errors?.isSell?.type === "pattern" && <p className="text-[#FF0000] text-[14px]">*Nhập sai định dạng số</p>}
    //         <button type='submit' className='text-[#fff] rounded-lg bg-[#24CF41] py-[15px]'>Cập nhật</button>
    //       </div>
    //     </SettingW.Left>
    //     <SettingW.Right>
    //       <div className='w-full h-full rounded-[10px] overflow-hidden'>
    //         <div className='flex justify-between px-[15px] bg-[#B9D2FF] items-center py-[20px]'>
    //           <span className='text-[#000650] font-medium'>Cấu hình thời gian thắng thua</span>
    //           <div
    //             className='text-[#fff] font-medium py-[10px] px-[20px] rounded-md border-[1px] border-solid border-[#fff] bg-gradient-to-t from-[#2050A6] to-[#1AA0B5] cursor-pointer'
    //             onClick={openModal}
    //           >
    //             Thêm mới
    //           </div>
    //         </div>
    //         <div className='px-[15px] py-[20px] bg-[#F0F4FF] flex flex-col gap-[20px] h-full overflow-y-auto'>
    //           {
    //             listTime.map((item, index) => (
    //               <div key={index} className='flex justify-between items-center bg-[#fff] p-[20px] rounded-sm shadow-sm'>
    //                 <span>{format(new Date(item.startDate), 'dd/MM/yyyy')}</span>
    //                 <span>{item.startTime} - {item.endTime}</span>
    //                 <span className={item.status == "BUY" ? 'text-[#00C923]' : 'text-[#EC4951]'}>{item.status == "BUY" ? 'Buy' : 'Sell'}</span>
    //                 <span className='cursor-pointer' onClick={() => handleDele(item.id)}><img src={dele} className="w-[20px]" /></span>
    //               </div>
    //             ))
    //           }
    //         </div>
    //       </div>
    //     </SettingW.Right>
    //   </SettingW.Body>
    // </SettingW>
  )
}

export default Setting